import React from "react";
import {
  BarChart,
  Legend,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Bar,
} from "recharts";

const BarChartCustom = (props) => {
  return (
    <ResponsiveContainer>
      <BarChart data={props.data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={props.primary_key} />
        <YAxis />
        <Tooltip />
        <Legend />
        {props.dataKeys.map((item) => (
          <Bar key={item.key} dataKey={item.key} fill={item.color} />
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
};

export default BarChartCustom;
