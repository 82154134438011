import React from 'react';

import { Form } from 'react-bootstrap';

const InputCustom = (props) => {
  return (
    <Form.Group>
      {
        props.label ? (
          <Form.Label>{props.label}</Form.Label>
        ) : (null)
      }
      <Form.Control className="input-text" {...props} type={props.type} placeholder={props.placeholder} />
    </Form.Group>
  )
}

export default InputCustom;