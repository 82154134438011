import React from "react";
import "./App.css";

// routes
import MainRoute from "./routes";

// own contexts
import UserContextProvider from "./contexts/UserContext";

import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/styles.scss";

function App() {
  return (
    <div className="App">
      <UserContextProvider>
        <MainRoute />
      </UserContextProvider>
    </div>
  );
}

export default App;
