import React, { useState, useEffect } from "react";
import axios from "axios";
import { Col, Row, Container, Spinner } from "react-bootstrap";
import { TiDownloadOutline } from "react-icons/ti";

// api
import {
  getLoansByMonths,
  getLoanLibraries,
  getLoans,
  getSummary,
} from "../../api/provider";

// constans
import { MONTHS, API_EFILM } from "../../constans";

// own components
import BarChart from "../../components/charts/barChart";
import PolarChart from "../../components/charts/PolarChart";
import SelectMonth from "../../components/filters/SelectMonths";
import SelectYear from "../../components/filters/SelectYear";
import Table from "../../components/table";
import Button from "../../components/buton";

const Home = () => {
  const [loansMonth, setLoansMonth] = useState([]);
  const [loans, setLoans] = useState();
  const [loanLibraries, setLoanLibraries] = useState(null);
  const [summary, setSummary] = useState(null);
  const [yearTotal, setYearTotal] = useState(0);
  const [year, setYear] = useState("");
  const [month, setMonth] = useState("");
  const [page, setPage] = useState(1);
  const [disabledBtn, setDisabledBtn] = useState(false);

  useEffect(() => {
    const loadLoanLibraries = async () => {
      const { status, data } = await getLoanLibraries();
      if (status === 200) {
        console.log(data);
        setLoanLibraries(data);
      }
    };
    const loadSummary = async () => {
      const { status, data } = await getSummary();
      if (status === 200) {
        setSummary(data);
      }
    };
    loadSummary();
    loadLoanLibraries();
  }, []);

  useEffect(() => {
    handleLoansByMonth();
  }, [year]);

  useEffect(() => {
    handleLoans();
  }, [month, year, page]);

  useEffect(() => {
    setPage(1);
  }, [month, year]);

  const serializerLoansByMonth = (data) => {
    setYearTotal(0);
    return data.map((item) => {
      setYearTotal((prev) => prev + item[1]);
      return { mes: MONTHS[item[0] - 1], prestamos: item[1] };
    });
  };

  const handleLoansByMonth = async () => {
    if (year !== "") {
      const response = await getLoansByMonths({ year: year });
      if (response.status === 200) {
        setLoansMonth(serializerLoansByMonth(response.data));
      }
    }
  };

  const handleLoans = async () => {
    if (year !== "" && month !== "") {
      const response = await getLoans({ year, month, page });
      console.log(response.data);
      if (response.status === 200) {
        setLoans(response.data);
      }
    }
  };

  const exportLoans = async () => {
    setDisabledBtn(true);
    axios({
      url:
        API_EFILM +
        "statistics/provider/loan-export/?year=" +
        year +
        "&month=" +
        month,
      method: "GET",
      responseType: "blob", // important
      headers: {
        Authorization: "JWT " + localStorage.getItem("tokenAuth"),
      },
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "loans.xls");
      document.body.appendChild(link);
      link.click();
      setDisabledBtn(false);
    });
  };

  return (
    <Container>
      <Row>
        <Col md={4}>
          {summary && (
            <div>
              <h2>TOTALES</h2>
              <div className="row">
                <div className="col badge-info">{summary.total} Un.</div>
                <div className="col badge-info">
                  {summary.amount_total} {process.env.REACT_APP_COIN}
                </div>
              </div>
            </div>
          )}
          {loanLibraries && (
            <div>
              <h2>Monto por cliente</h2>
              {loanLibraries[0].map((item, index) => (
                <div className="card-info" key={index}>
                  <span className="title">{item.toUpperCase()}</span>
                  <p className="info">
                    {loanLibraries[2][index]} {process.env.REACT_APP_COIN}
                  </p>
                </div>
              ))}
            </div>
          )}
        </Col>
        <Col md={8} style={{ height: 850, justifyContent: "center" }}>
          <h2>Nº Préstamos por cliente</h2>
          <PolarChart
            title="Consumo por cliente"
            labels={loanLibraries ? loanLibraries[0] : []}
            data={loanLibraries ? loanLibraries[1] : []}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <SelectYear handleSelect={setYear} />
        </Col>
        <Col md={6}>
          <h4>Préstamos/año {yearTotal}</h4>
        </Col>
      </Row>
      <Row>
        <Col style={{ height: 500 }}>
          <BarChart
            data={loansMonth}
            primary_key="mes"
            dataKeys={[{ key: "prestamos", color: "#faaa00" }]}
          />
        </Col>
      </Row>
      <Row className="mt-4 pt-4">
        <Col md={6}>
          <SelectMonth year={year} handleSelect={setMonth} />
        </Col>
        <Col md={4}>
          <Button
            disabled={disabledBtn}
            className="online-cprimary"
            onClick={exportLoans}
          >
            Exportar{" "}
            {disabledBtn ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              <TiDownloadOutline />
            )}
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <Table
            header={["Audiovisual", "Fecha y hora"]}
            page={page}
            changePage={setPage}
            next={loans ? (loans.links.next ? true : false) : false}
            previous={loans ? (loans.links.previous ? true : false) : false}
          >
            {loans
              ? loans.results.map((item, i) => (
                  <tr key={i}>
                    <td>{item.name}</td>
                    <td>{item.created}</td>
                  </tr>
                ))
              : null}
          </Table>
        </Col>
      </Row>
    </Container>
  );
};

export default Home;
