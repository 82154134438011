import React, { useState, useEffect } from "react";
import { Col, Row, Container, Spinner } from "react-bootstrap";
import { TiDownloadOutline } from "react-icons/ti";
import axios from "axios";

// api
import { getContent, getClients } from "../../api/provider";

// constans
import { API_EFILM } from "../../constans";

// own components
import Table from "../../components/table";
import Select from "../../components/select";
import Button from "../../components/buton";

const Contenido = () => {
  const [disabledBtn, setDisabledBtn] = useState(false);
  const [contents, setContents] = useState(null);
  const [clients, setclients] = useState([]);
  const [client, setClient] = useState(null);
  const [count, setCount] = useState(0);
  const [amount, setAmount] = useState(0);

  const loadContent = async () => {
    let payload = {};
    if (client !== "" && client !== null) {
      payload = { client: client };
    }
    const { status, data } = await getContent(payload);
    if (status === 200) {
      setContents(data.results);
      setCount(data.cont);
      setAmount(data.amount);
    }
  };

  useEffect(() => {
    const loadClients = async () => {
      const { status, data } = await getClients();
      if (status === 200) {
        let temp = data.map((item) => ({ value: item, name: item }));
        temp = [{ value: "", name: "-- Selecciona un cliente --" }, ...temp];
        setclients(temp);
      }
    };
    loadClients();
  }, []);

  useEffect(() => {
    loadContent();
  }, [client]);

  const exportLoanContent = async () => {
    setDisabledBtn(true);
    axios({
      url: API_EFILM + "statistics/provider/export-loans-content/",
      method: "GET",
      responseType: "blob", // important
      headers: {
        Authorization: "JWT " + localStorage.getItem("tokenAuth"),
      },
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `loans_${new Date().toString()}.xls`);
      document.body.appendChild(link);
      link.click();
      setDisabledBtn(false);
    });
  };

  return (
    <Container>
      <h2>Consumo por contenido</h2>
      <div>
        <h2>TOTALES</h2>
        <div className="row">
          <div className="col badge-info">{count} Un.</div>
          <div className="col badge-info">
            {amount} {process.env.REACT_APP_COIN}
          </div>
        </div>
      </div>
      <Row>
        <Col>
          <Select options={clients} handleSelect={setClient} />
        </Col>
        <Col>
          <Button
            disabled={disabledBtn}
            className="online-cprimary"
            onClick={exportLoanContent}
          >
            Exportar{" "}
            {disabledBtn ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              <TiDownloadOutline />
            )}
          </Button>
        </Col>
      </Row>

      <Row>
        <Col>
          <Table header={["Contenido", "Nº préstamos", "Monto"]}>
            {" "}
            {contents &&
              contents.map((item, index) => (
                <tr key={index}>
                  <td>{item[0]}</td>
                  <td>{item[1]}</td>
                  <td>
                    {item[2]} {process.env.REACT_APP_COIN}
                  </td>
                </tr>
              ))}
          </Table>
        </Col>
      </Row>
    </Container>
  );
};

export default Contenido;
