import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { FiLogOut } from "react-icons/fi";

import { UserContext } from "../../contexts/UserContext";
import { setValueStorage } from "../../helpers";

import { Navbar, Nav } from "react-bootstrap";

const Header = () => {
  const userContext = useContext(UserContext);
  let history = useHistory();

  const logout = () => {
    setValueStorage("tokenAuth", "");
    userContext.setIsAuth(false);
    history.push("/");
  };

  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      style={{ background: "#e46b60" }}
      variant="dark"
    >
      <Navbar.Brand href="/">The Shelf</Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto">
          <Nav.Link href="/content">CONTENIDO</Nav.Link>
          <Nav.Link href="/clients">CLIENTES</Nav.Link>
        </Nav>
        <Nav>
          <Nav.Link onClick={() => logout()} href="#">
            Cerrar sesión <FiLogOut />
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Header;
