import React, { createContext, useState } from "react";

//helpers
import { getValueStorage } from "../helpers";
export const UserContext = createContext();

const UserContextProvider = (props) => {
  const checkLogged = getValueStorage("tokenAuth") ? true : false;
  const [isAuth, setIsAuth] = useState(checkLogged);
  const [userInfo, setUserInfo] = useState(null);

  return (
    <UserContext.Provider value={{ isAuth, setIsAuth, userInfo, setUserInfo }}>
      {props.children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
