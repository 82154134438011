import { DateTime } from "luxon";

/**
 * return either the content from key param or null if content there is no exist.
 * @param {string} key - the key property to check out in the localstorage
 */
const getValueStorage = (key) => localStorage.getItem(key);

/**
 * return Null
 * @param {string} key - the key property to store in the localstorage
 * @param {any} value - the value from the key to store in the localstorage
 */
const setValueStorage = (key, value) => localStorage.setItem(key, value);

const getDateFormat = (dateStr, format = "dd LLL yyyy") => {
  return DateTime.fromISO(dateStr).toFormat(format);
};

export { getValueStorage, setValueStorage, getDateFormat };
