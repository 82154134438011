import React from 'react';

// Own components
import Footer from '../components/footer';
import Header from '../components/header';

const DefaultLayout = (props) => (
  <>
    <Header />
    <main style={{ marginTop: 78, flex: 1 }} > 
      {props.children} 
    </main>
    <Footer />
  </>
)
export default DefaultLayout;
