import React from 'react';

import { Button } from 'react-bootstrap';

const ButtonCustom = (props) => {
  return (
    <Button variant="outline-success" {...props}>
      {props.children}
    </Button>
  )
}

export default ButtonCustom;