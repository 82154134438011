import React from 'react';

import { Form } from 'react-bootstrap';

const SelectCustom = (props) => {
  return (
    <Form>
      <Form.Group>
        { props.label ? (<Form.Label>{props.label}</Form.Label>) : null}
        <Form.Control as="select" onChange={ e => props.handleSelect(e.target.value)}>
          {
            props.options.map(item => (
              <option key={item.value} value={item.value}>{item.name} </option>
            ))
          }
        </Form.Control>
      </Form.Group>
    </Form>
  )
}

export default SelectCustom;