import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";

// apis
import { getClientTiers } from "../../api/clients";

// components
import Table from "../../components/table";

import { getDateFormat } from "../../helpers";
export const COIN = process.env.REACT_APP_COIN;

const ClientsProviderPage = (props) => {
  const [clientTiers, setClientTiers] = useState(null);

  useEffect(() => {
    const loadClientTier = async () => {
      const { status, data } = await getClientTiers();
      if (status === 200) {
        console.log(data);
        setClientTiers(data);
      }
    };
    loadClientTier();
  }, []);

  return (
    <Container>
      <h2>Clientes</h2>
      <Row>
        <Col>
          <Table header={["Cliente", "Tier", "Packs", "Creado", "Zona"]}>
            {clientTiers &&
              clientTiers.map((item, index) => (
                <tr key={index}>
                  <td>{item?.client?.platform_name}</td>
                  <td>{item?.tier?.number}</td>
                  <td>{item?.tier?.packs.join(", ")}</td>
                  <td>{getDateFormat(item?.created)}</td>
                  <td>{item?.tier?.zone}</td>
                </tr>
              ))}
          </Table>
        </Col>
      </Row>
    </Container>
  );
};

export default ClientsProviderPage;
