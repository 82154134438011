import React, { useState, useEffect } from "react";

// api
import { getYears } from "../../api/provider";

// OWN COMPONENTS
import Select from "../select";

// constans

const SelectMonths = (props) => {
  const [options, setOptions] = useState([
    { value: "", name: "-- Selecciona un año --" },
  ]);

  useEffect(() => {
    loadYear();
  }, []);

  const loadYear = async () => {
    const response = await getYears();
    if (response.status === 200) {
      let data = response.data.map((item) => ({
        value: item,
        name: item,
      }));
      setOptions(data);
      props.handleSelect(data[0].value);
    }
  };

  return <Select options={options} handleSelect={props.handleSelect} />;
};

export default SelectMonths;
