import React from "react";

const Footer = () => {
  return (
    <footer className="page-footer font-small blue pt-4">
      <div className="footer-copyright text-center py-3">
        © {new Date().getFullYear()} Copyright:
        <a className="link-primary" target="_blank" href="https://theshelf.tv">
          The Shelf TV
        </a>
      </div>
    </footer>
  );
};

export default Footer;
