import axios from 'axios';
import { API_EFILM } from '../constans';

const headers = {
  'Content-Type': 'application/json'
}


const api = {
  handleApi() {
      const client = axios.create({
          baseURL: API_EFILM,
          headers: headers
      });
      client.interceptors.request.use(config => {
          const token = localStorage.getItem('tokenAuth')
          if (token) {
              config.headers.Authorization = `JWT ${token}`
          }
          return config
      }, (error) => { 
          return Promise.reject(error)})
      return client
  }
}
export default api.handleApi();