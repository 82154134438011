import React from 'react';

import Footer from '../components/footer';

const SecondaryLayout = (props) => (
  <>
    <main className="bgd-primary center-vh-content">
      {props.children}
    </main>
    <Footer />
  </>
)

export default SecondaryLayout;