import { methodGet } from "./methods";
import { API_EFILM } from "../constans";

export async function getLoansByMonths(query) {
  return methodGet("statistics/provider/loans-year", query);
}

export async function getYears() {
  return methodGet("statistics/provider/get-years", {});
}

export async function getMonths(payload) {
  return methodGet("statistics/provider/get-months", payload);
}

export async function getClients() {
  return methodGet("statistics/provider/get-clients", {});
}

export async function getLoans(query) {
  return methodGet("statistics/provider/", query);
}

export const getLoanLibraries = (payload) => {
  return methodGet("statistics/provider/loan-libraries/", payload);
};

export const getSummary = () => {
  return methodGet("statistics/provider/loan-summary/", {});
};

export const getContent = (payload) => {
  return methodGet("statistics/provider/loans-content/", payload);
};

export const loansExportExcel = (payload) => {
  return methodGet("statistics/provider/loan-export/", payload);
};
