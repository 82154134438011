export const API_EFILM = process.env.REACT_APP_API_EFILM

export const MONTHS = [
  "Enero", 
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre", 
  "Diciembre"
]