import React from "react";

import { Table, Pagination } from "react-bootstrap";

const TableCustom = (props) => {
  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          {props.header.map((item, i) => (
            <td key={i}>{item}</td>
          ))}
        </tr>
      </thead>
      {props.changePage && (
        <tfoot>
          <tr>
            <td colSpan={props.header.length}>
              <Pagination style={{ justifyContent: "center" }}>
                {props.previous ? (
                  <Pagination.Prev
                    onClick={() => props.changePage(props.page - 1)}
                  />
                ) : null}
                <Pagination.Item active>{props.page}</Pagination.Item>
                {props.next ? (
                  <Pagination.Next
                    onClick={() => props.changePage(props.page + 1)}
                  />
                ) : null}
              </Pagination>
            </td>
          </tr>
        </tfoot>
      )}
      <tbody>{props.children}</tbody>
    </Table>
  );
};

export default TableCustom;
