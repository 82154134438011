import PropTypes from "prop-types";
import React from "react";
import { PolarArea } from "react-chartjs-2";

const PolarChat = ({ labels, data, title }) => {
  const dataChart = {
    labels: labels,
    datasets: [
      {
        label: title,
        data: data,
        backgroundColor: [
          "#79BBD28A",
          "#47C2B48A",
          "#D29B798A",
          "#22675E8A",
          "#8892D78A",
          "#7DD4D18A",
          "#B8E6B38A",
          "#E8D2BA8A",
        ],
      },
    ],
  };

  return <PolarArea data={dataChart} />;
};

PolarChat.propTypes = {
  data: PropTypes.any,
  labels: PropTypes.any,
  title: PropTypes.any,
};

export default PolarChat;
